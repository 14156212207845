import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import SanityBlockContent from "@sanity/block-content-to-react"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import ImageCarousel from "../Carousel"
import styled from "styled-components"
import { serializers } from "../../utils/serializers"
import { MediaQueries } from "../../utils/responsive"

const StyledJumboProductContainer = styled(Container)`
  margin-top: 80px;

  @media ${MediaQueries.mobileUp} {
    margin-top: 0;
  }
  .rightPart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    height: ${props => `${props.height}vh`};
    .rightTopPart {
      background: ${props => props.theme.colors.green};
      width: 100%;
      color: ${props => props.theme.colors.white};
      padding: 1.5rem;
      transition: all 0.2s ease-in-out;
      height: 80%;

      .description {
        overflow: hidden;
        p {
          font-size: initial;
          @media ${MediaQueries.mobileUp} {
            font-size: 14px;
          }
        }
      }
    }
    .rightBottomPart {
      background: ${props => props.theme.colors.greenOp};
      height: 20%;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: ${props => props.theme.colors.greenOp};
        box-shadow: inset 0px 0px 5px #00000033;
      }

      .buyButton {
        font-size: 16px;
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1px;
        &:hover {
          color: ${props => props.theme.colors.white};
          text-decoration: underline;
        }
      }
    }
  }
`

const JumboProduct = ({ highlightedProduct }) => {
  const { i18n, t } = useTranslation("shop")
  const language = i18n.language

  const carouselHeight = 90 //vh

  return (
    <StyledJumboProductContainer height={carouselHeight}>
      <Row className="justify-content-center justify-content-md-end mb-3">
        <Col xs={12} md={9} className="pr-md-0">
          <div className="carouselHolder">
            <ImageCarousel
              images={highlightedProduct.node.defaultProductVariant.images}
              title={
                highlightedProduct.node.defaultProductVariant.title.translate
              }
              link={highlightedProduct.node._rawSlug[language].current}
              carouselHeight={carouselHeight}
            />
          </div>
        </Col>
        <Col xs={12} md={3} className="pl-md-0 rightPart">
          <div className="rightTopPart">
            <h2>
              {highlightedProduct.node.defaultProductVariant.title.translate}
            </h2>
            <div className="description">
              <SanityBlockContent
                blocks={highlightedProduct.node._rawBody[language][0]}
                projectId={process.env.SANITY_PROJECTID}
                dataset={process.env.SANITY_DATASET}
                serializers={serializers}
              />
            </div>
          </div>
          <div className="rightBottomPart">
            <div className="buyPart">
              <Link
                to={`/${language}/webshop/${highlightedProduct.node._rawSlug[language].current}`}
                className="buyButton"
              >
                {t("order")}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </StyledJumboProductContainer>
  )
}

JumboProduct.propTypes = {
  highlightedProduct: PropTypes.object.isRequired,
}

export default JumboProduct
