import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Row, Col } from "reactstrap"

const StyledProductsRow = styled(Row)`
  margin-top: 80px;
  padding: 0 1.5rem;
  .imagePart {
    height: 75%;
    min-height: 100px;
    max-height: 250px;
    background: ${props => props.theme.colors.green};
    width: 100%;
    overflow: hidden;
    cursor: pointer;
  }
  .titlePart {
    height: 25%;
    background: ${props => props.theme.colors.green};
    padding: 1rem;
    cursor: pointer;
  }
`

const Products = ({ products, updateHighlightedProduct }) => {
  const { i18n } = useTranslation("shop")
  const language = i18n.language

  if (products.length < 1) {
    return (
      <StyledProductsRow className="mt-4">
        <Col xs={12} lg={6}>
          <br />
          <br />
          <h2>There are no products matching the filter</h2>
        </Col>
      </StyledProductsRow>
    )
  }

  return (
    <StyledProductsRow className="mt-4">
      {products.map((product, i) => (
        <Col
          xs={12}
          md={6}
          lg={3}
          key={product.node._id}
          className={
            i > 0
              ? i % 2 === 0
                ? "py-3 pr-md-0"
                : "py-3 px-md-0"
              : "py-3 pl-md-0"
          }
          onClick={() => updateHighlightedProduct(product)}
        >
          <div className="imagePart">
            <GatsbyImage
              image={getImage(
                product.node.defaultProductVariant.images[0].asset
              )}
              style={{ height: "100%" }}
            />
          </div>
          <div className="titlePart">
            <h3>{product.node.title.translate}</h3>
          </div>
        </Col>
      ))}
    </StyledProductsRow>
  )
}

Products.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateHighlightedProduct: PropTypes.func.isRequired,
}
export default Products
