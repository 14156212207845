import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselCaption
} from "reactstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledCarouselCaption = styled(CarouselCaption)`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: unset;
    bottom: unset;
    h3 {
        color: ${props => props.theme.colors.white};
        text-shadow: 0px 1px 1px #000000dd;
        font-size: 2rem;
    }
`

const StyledCarousel = styled(Carousel)`
    max-height: ${props => `${props.carouselHeight}vh`};
    .carousel-inner {
        max-height: ${props => `${props.carouselHeight}vh`};
        .carousel-item {
            max-height: ${props => `${props.carouselHeight}vh`};
        }
    }
`
const ImageCarousel = props => {
    const [activeIndex, setActiveIndex] = useState(0)
    const [animating, setAnimating] = useState(false)

    const next = () => {
        if (animating) return
        const nextIndex =
            activeIndex === props.images.length - 1 ? 0 : activeIndex + 1
        setActiveIndex(nextIndex)
    }

    const previous = () => {
        if (animating) return
        const nextIndex =
            activeIndex === 0 ? props.images.length - 1 : activeIndex - 1
        setActiveIndex(nextIndex)
    }

    const slides = props.images.map(item => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={(Math.random() * Math.random()).toString()}
            >
                <GatsbyImage
                    image={getImage(item.asset)}
                    alt={item.asset.originalFilename}
                    style={{ maxHeight: `${props.carouselHeight}vh` }}
                />
                <Link to={props.link}>
                    <StyledCarouselCaption
                        captionHeader={props.title}
                        captionText={""}
                    />
                </Link>
            </CarouselItem>
        )
    })

    return (
        <StyledCarousel
            interval={false}
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            carouselHeight={props.carouselHeight}
        >
            {slides}
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </StyledCarousel>
    )
}

ImageCarousel.propTypes = {
    link: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string,
    carouselHeight: PropTypes.number
}

ImageCarousel.defaultProps = {
    link: "",
    title: "",
    carouselHeight: 50
}

export default ImageCarousel
