import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import CategorySelector from "../components/CategorySelector"
import JumboProduct from "../components/JumboProduct"
import Products from "../components/Products"
import Navbar2 from "../components/Navbar2"
import { isBrowser } from "../utils/isBrowser"

const WebshopPage = props => {
  const { i18n } = useTranslation("shop")
  const language = i18n.language
  const allProducts = props.data.products.edges
  const [stateProducts, setStateProducts] = useState(allProducts)
  const [highlightedProduct, setHighlightedProduct] = useState(allProducts[0])

  const filterProducts = (filter, allProducts) => {
    if (filter.length > 0) {
      return allProducts.filter(prod => {
        const match = prod.node.categories.map(
          cat =>
            filter.map(item => item.label).indexOf(cat._rawTitle[language]) > -1
        )
        return match.some(i => i === true) && prod
      })
    } else {
      return allProducts
    }
  }

  const updateHighlightedProduct = prod => {
    setHighlightedProduct(prod)
    isBrowser && window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const updateFilter = filter =>
    setStateProducts(filterProducts(filter, allProducts))

  return (
    <Layout alternateLink={props.alternateLinks}>
      <SEO title="Justanatomy.com | Webshop" />
      <JumboProduct highlightedProduct={highlightedProduct} />
      <Container>
        <Row className="mt-4 justify-content-end">
          <Col sm={12} lg={6} xl={6}>
            <CategorySelector
              language={language}
              categories={props.data.categories.edges}
              updateFilter={updateFilter}
            />
          </Col>
        </Row>
        <Products
          products={stateProducts}
          updateHighlightedProduct={updateHighlightedProduct}
        />
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} className="px-0 position-relative">
            <Navbar2 fixed={true} topNav={false} narrow={true} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Shop($language: String) {
    products: allSanityProduct(sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          _id
          _rawSlug
          title {
            translate(language: $language)
          }
          defaultProductVariant {
            title {
              translate(language: $language)
            }

            images {
              asset {
                gatsbyImageData
              }
            }
          }
          _rawBody
          _rawSlug
          categories {
            _rawTitle
          }
        }
      }
    }
    categories: allSanityCategory {
      edges {
        node {
          title {
            translate(language: $language)
          }
          _rawSlug
          parents {
            _rawTitle
          }
        }
      }
    }
  }
`

export default WebshopPage
