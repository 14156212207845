import React from "react"
export const serializers = {
    types: {
        youtube: props => {
            return (
                <div className="videoContainer">
                    <iframe
                        src={props.node.url
                            .replace("watch", "embed")
                            .replace("?v=", "/")}
                        frameborder="0"
                        allowFullScreen
                        className="video"
                        title={(Math.random() * Math.random()).toString()}
                    ></iframe>
                </div>
            )
        }
    }
}
